
















import { defineComponent, SetupContext } from '@vue/composition-api'

import CloseIcon from './icons/CloseIcon.vue'

import { drawerInnerProps, useDrawerInner } from './DrawerInner.hooks'
import { DrawerInnerProps, UseDrawerInnerProvides } from './DrawerInner.contracts'

/**
 * Component capable to render inner part of drawer.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const DrawerInner = defineComponent({
  name: 'DrawerInner',
  components: { CloseIcon },
  props: drawerInnerProps,
  setup (props: DrawerInnerProps, ctx: SetupContext): UseDrawerInnerProvides {
    return useDrawerInner(props, ctx.emit)
  }
})
export default DrawerInner
